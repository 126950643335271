import { ModelAbstract } from '../index';
import { ORDER_TYPES, PRICEBOOK_TAGS } from '../../common/enums';
import { PriceBookContextModel } from '../../Context';

class CommonSectionModel extends ModelAbstract {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    this._ignoreOnExport.push(
      'customerOrder',
      'isSupportsSpeechAnalytics',
      'isSupportsMatchAddOnTermLengthToLocationQuote',
      'isSupportsDaas',
      'isAddon'
    );
  }

  /** @type {CustomerOrderModel} */
  get customerOrder() {
    return this.controller.customerOrder;
  }

  get lockedState() {
    return this.controller.appState.locked;
  }

  get disabledState() {
    return this.controller.appState.disabled;
  }

  get isSupportsSpeechAnalytics() {
    return PriceBookContextModel.hasPricebookTag(PRICEBOOK_TAGS.SUPPORTS_SERVICE_INSIGHT_INITIAL_SETUP);
  }

  get isSupportsMatchAddOnTermLengthToLocationQuote() {
    return PriceBookContextModel.hasPricebookTag(PRICEBOOK_TAGS.MATCH_ADD_ON_TERM_LENGTH_TO_LOCATION_QUOTE);
  }

  get isSupportsDaas() {
    return PriceBookContextModel.hasPricebookTag(PRICEBOOK_TAGS.SUPPORTS_NEW_DAAS);
  }

  get isAddon() {
    return this.customerOrder.orderType === ORDER_TYPES.ADD_ON;
  }
}

export default CommonSectionModel;
