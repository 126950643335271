const SAVE_FIELDS_NEW_NEW = [
  'lineItems',
  'numLines',
  'numLocations',
  'billingDealerId',
  'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  'isLocationUnique',
  'customerNameLength',
  'customerNameIsUnique',
  'blankIctpPartner',
  'ictpConfirmationRequired',
  'NRC_MRC_LineItems',
  'locationName',
  'saveButtonSeApproval',
  'unlimitedCallRoutingCountMixedWarning',
  'unlimitedCallRoutingLimitWarning',
  'allocationsForFinalizedMo',
  'numStarBoxesError',
  'customerInstallStarPlus',
  'solutionType',
  'location4gRequiresSdwan',
  'madPoolOverAllocated',
  'spiffOverAllocated',
  'madUpfrontOverAllocated',
  'madRecurOverAllocated',
  'starCloudWithCustomerInstallation',
  'onePerGroup',
  'negativeAmount',
  'starFaxDigitalMixedAtLocationWarning',
  'legacyLineItemsLocations',
  'dbaError',
  'starPhoneOnlyExtensionExceedUsersWarning',
  'pciComplianceWarning',
  'bcUserMo',
  'bcUserLocation',
  'leaseCustomPaymentAmount',
  'customerInfoErrors',
  'addendumErrors',
  'allowedToAllocateOnConditionAtLocationError',
  'invalidateItemsNotAllowedToAddOnConditionError',
  'switchvoxOrSipStationMustBePresent',
  'contactCenterConcurrencyItemMustBePresent',
  'smartOfficeGatewayKitValidation',
  'smartOfficeAccessDoorLicenseValidation',
  'smartOfficeLicenceOverallocationWarning',
  'switchvoxApplianceValidation',
  'standaloneCPaaSValidationError',
  'numSangomaCXUsersExceedsNumUCaaSUsersError',
  'sangomaCXUsersRequiredError',
  'sangomaCXConcurrencyItemsValidation',
  'calculationRequired',
];

const SAVE_FIELDS_NEW_LOCATIONS = [
  'lineItems',
  'numLines',
  'numLocations',
  'billingDealerId',
  'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  'isLocationUnique',
  'customerNameLength',
  'customerNameIsUnique',
  'blankIctpPartner',
  'ictpConfirmationRequired',
  'NRC_MRC_LineItems',
  'locationName',
  'saveButtonSeApproval',
  'unlimitedCallRoutingCountMixedWarning',
  'unlimitedCallRoutingLimitWarning',
  'allocationsForFinalizedMo',
  'numStarBoxesError',
  'customerInstallStarPlus',
  'solutionType',
  'location4gRequiresSdwan',
  'madPoolOverAllocated',
  'spiffOverAllocated',
  'madUpfrontOverAllocated',
  'madRecurOverAllocated',
  'starCloudWithCustomerInstallation',
  'onePerGroup',
  'negativeAmount',
  'starFaxDigitalMixedAtLocationWarning',
  'legacyLineItemsLocations',
  'starPhoneOnlyExtensionExceedUsersWarning',
  'pciComplianceWarning',
  'bcUserMo',
  'bcUserLocation',
  'leaseCustomPaymentAmount',
  'customerInfoErrors',
  'addendumErrors',
  'allowedToAllocateOnConditionAtLocationError',
  'invalidateItemsNotAllowedToAddOnConditionError',
  'switchvoxOrSipStationMustBePresent',
  'smartOfficeGatewayKitValidation',
  'smartOfficeAccessDoorLicenseValidation',
  'smartOfficeLicenceOverallocationWarning',
  'switchvoxApplianceValidation',
  'standaloneCPaaSValidationError',
  'numSangomaCXUsersExceedsNumUCaaSUsersError',
  'sangomaCXUsersRequiredError',
  'calculationRequired',
];

const SAVE_FIELDS_ADD_ON = [
  'lineItems',
  'billingDealerId',
  'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  'isLocationUnique',
  'customerNameLength',
  'customerNameIsUnique',
  'blankIctpPartner',
  'ictpConfirmationRequired',
  'NRC_MRC_LineItems',
  'saveButtonSeApproval',
  'unlimitedCallRoutingCountMixedWarning',
  'unlimitedCallRoutingLimitWarning',
  'customerInstallStarPlus',
  'starCloudWithCustomerInstallation',
  'solutionType',
  'starFaxDigitalMixedAtLocationWarning',
  'legacyLineItemsAddons',
  'onePerGroup',
  'negativeAmount',
  'starPhoneOnlyExtensionExceedUsersWarning',
  'pciComplianceWarning',
  'bcUserMo',
  'bcUserLocation',
  'leaseCustomPaymentAmount',
  'customerInfoErrors',
  'invalidateItemsNotAllowedToAddOnConditionError',
  'switchvoxOrSipStationMustBePresent',
  'smartOfficeGatewayKitValidation',
  'smartOfficeAccessDoorLicenseValidation',
  'smartOfficeLicenceOverallocationWarning',
  'numSangomaCXUsersExceedsNumUCaaSUsersError',
  'sangomaCXUsersRequiredError',
  'calculationRequired',
];

const SAVE_FIELDS_REWRITE = [
  'lineItems',
  'numLines',
  'numLocations',
  'billingDealerId',
  'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  'isLocationUnique',
  'customerNameLength',
  // 'customerNameIsUnique',
  'blankIctpPartner',
  'ictpConfirmationRequired',
  'NRC_MRC_LineItems',
  'locationName',
  'saveButtonSeApproval',
  'unlimitedCallRoutingCountMixedWarning',
  'unlimitedCallRoutingLimitWarning',
  'allocationsForFinalizedMo',
  'numStarBoxesError',
  'customerInstallStarPlus',
  'solutionType',
  'location4gRequiresSdwan',
  'madPoolOverAllocated',
  'spiffOverAllocated',
  'madUpfrontOverAllocated',
  'madRecurOverAllocated',
  'starCloudWithCustomerInstallation',
  'onePerGroup',
  'negativeAmount',
  'starFaxDigitalMixedAtLocationWarning',
  'legacyLineItemsLocations',
  'starPhoneOnlyExtensionExceedUsersWarning',
  'pciComplianceWarning',
  'bcUserMo',
  'bcUserLocation',
  'leaseCustomPaymentAmount',
  'customerInfoErrors',
  'allowedToAllocateOnConditionAtLocationError',
  'invalidateItemsNotAllowedToAddOnConditionError',
  'switchvoxOrSipStationMustBePresent',
  'smartOfficeGatewayKitValidation',
  'smartOfficeAccessDoorLicenseValidation',
  'smartOfficeLicenceOverallocationWarning',
  'numSangomaCXUsersExceedsNumUCaaSUsersError',
  'sangomaCXUsersRequiredError',
  'calculationRequired',
];

const SAVE_FIELDS_REDUCTION = [
  'lineItems',
  'numLines',
  'numLocations',
  'billingDealerId',
  'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  'isLocationUnique',
  'customerNameLength',
  'customerNameIsUnique',
  'blankIctpPartner',
  'ictpConfirmationRequired',
  'NRC_MRC_LineItems',
  'saveButtonSeApproval',
  'unlimitedCallRoutingCountMixedWarning',
  'unlimitedCallRoutingLimitWarning',
  'calculationRequired',
];

const CALC_FIELDS_NEW_NEW = [
  'lineItems',
  'numLines',
  'numLocations',
  'billingDealerId',
  // 'dealerContactUserId',
  'quoteTitle',
  // 'inactiveLineItems',
  // 'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  // 'isLocationUnique',
  'customerNameLength',
  // 'customerNameIsUnique',
  'NRC_MRC_LineItems',
  'locationName',
  'onePerGroup',
  'legacyLineItemsLocations',
];

const CALC_FIELDS_NEW_LOCATIONS = [
  'lineItems',
  'numLines',
  'numLocations',
  'billingDealerId',
  // 'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  // 'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  // 'isLocationUnique',
  'customerNameLength',
  // 'customerNameIsUnique',
  'NRC_MRC_LineItems',
  'locationName',
  'onePerGroup',
  'legacyLineItemsLocations',
];

const CALC_FIELDS_ADD_ON = [
  'lineItems',
  'billingDealerId',
  // 'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  // 'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  // 'isLocationUnique',
  'customerNameLength',
  // 'customerNameIsUnique',
  'NRC_MRC_LineItems',
  'solutionType',
  'legacyLineItemsAddons',
  'onePerGroup',
];

const CALC_FIELDS_REWRITE = [
  'lineItems',
  'numLines',
  'numLocations',
  'billingDealerId',
  // 'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  // 'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  // 'isLocationUnique',
  'customerNameLength',
  // 'customerNameIsUnique',
  'NRC_MRC_LineItems',
  'locationName',
  'onePerGroup',
  'legacyLineItemsLocations',
];

const CALC_FIELDS_REDUCTION = [
  'lineItems',
  'numLines',
  'numLocations',
  'billingDealerId',
  // 'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  // 'spiffAmount',
  // 'spiffAvailableAmountRc',
  // 'spiffAvailableAmountNrc',
  // 'isLocationUnique',
  'customerNameLength',
  // 'customerNameIsUnique',
  'NRC_MRC_LineItems',
];

export const APP_STATE = {
  SAVE_FIELDS: {
    NEW_NEW: SAVE_FIELDS_NEW_NEW,
    NEW_LOCATIONS: SAVE_FIELDS_NEW_LOCATIONS,
    ADD_ON: SAVE_FIELDS_ADD_ON,
    REWRITE: SAVE_FIELDS_REWRITE,
    REDUCTION: SAVE_FIELDS_REDUCTION,
  },
  CALC_FIELDS: {
    NEW_NEW: CALC_FIELDS_NEW_NEW,
    NEW_LOCATIONS: CALC_FIELDS_NEW_LOCATIONS,
    ADD_ON: CALC_FIELDS_ADD_ON,
    REWRITE: CALC_FIELDS_REWRITE,
    REDUCTION: CALC_FIELDS_REDUCTION,
  },
};
