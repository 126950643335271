import React, { Component } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import translator from '../../common/translate';
import Dialog from '../Dialog';
import PropTypes from 'prop-types';
import './css/quote-history.css';
import TemplateStringReplace from '../../common/helpers/format/TemplateStringReplace';
import ExternalLink from '../ExternalLink';
import {
  FINANCING_OPTIONS,
  SALES_OPS_TOGGLES,
  SANGOMA_CX_USER_TYPES_QUOTE_HISTORY,
  SCHEDULE_TYPES_QUOTE_HISTORY,
  WORKSPACE_TYPES_QUOTE_HISTORY,
} from '../../common/enums';
import dayjs from 'dayjs';

const QUOTE_HISTORY_ACTIONS_MAP = {
  CREATE_REVISION: 'msg_create_revision',
  SAVED: 'msg_saved',
  FINALIZED: 'msg_finalized',
  UNFINALIZED: 'msg_unfinalized',
  CLOSED: 'msg_closed',
  SE_SUBMITTED_FOR_APPROVAL: 'msg_se_submitted_for_approval',
  SE_WITHDRAWN: 'msg_se_withdrawn',
  SE_REJECTED: 'msg_se_rejected',
  SE_APPROVED: 'msg_se_approved',
  SE_WITHDRAWN_CRM: 'msg_se_withdrawn_crm',
  SIGNED_AND_FINALIZED: 'msg_signed_and_finalized',
  SENT_FOR_SIGNATURE: 'msg_sent_for_signature',
  LOCATION_NAME_UPDATED: 'msg_location_name_updated',
  AMENDING: 'msg_amending',
  AMENDMENT_CANCELED: 'msg_amendment_canceled',
  AMENDMENT_TASK_NUMBER_CHANGED: 'msg_amendment_task_number_changed',
  VOIDED: 'msg_voided',
  ESIGNATURES_FAILED: 'msg_signature_failed',
  SIGNATURE_REASSIGNED: 'msg_signature_reassigned',
  LOCATION_FINALIZED: 'msg_quote_history_action_location_finalized',
  LOCATION_UNFINALIZED: 'msg_quote_history_action_location_unfinalized',
  MARKER_FIELD_OVERRIDE_CREATED: 'msg_marker_override_created',
  MARKER_FIELD_OVERRIDE_DELETED: 'msg_marker_override_deleted',
  MARKER_FIELD_OVERRIDE_UPDATED: 'msg_marker_override_updated',
  CUSTOMER_DAAS_WORKSPACE_TYPE_RESET: 'msg_quote_history_customer_daas_workspace_type_reset',
  CUSTOMER_SANGOMA_CX_USER_TYPE_RESET: 'msg_customer_sangoma_cx_user_type_reset',
  CUSTOMER_SANGOMA_CX_DATA_EXPORT_CHANGED: 'msg_customer_sangoma_cx_data_export_changed',
  CUSTOMER_SANGOMA_CX_DATA_EXPORT_RESET: 'msg_customer_sangoma_cx_data_export_reset',
  CUSTOMER_SPEECH_ANALYTICS_CHANGED: 'msg_quote_history_speech_analytics_logged_upon_finalization',
  CUSTOMER_SPEECH_ANALYTICS_RESET: 'msg_quote_history_speech_analytics_reset_upon_voiding_unfinalization',
  LEASE_CREDIT_APP_EMAIL_SENT: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_quote_history_action_lease_credit_app_email_sent' });
    const na = intl.formatMessage({ id: 'msg_not_available_abbreviation' });
    const additionalCCEmailList = [];

    for (let key in quoteActionParameters) {
      if (!quoteActionParameters.hasOwnProperty(key)) {
        continue;
      }

      const matches = key.match(/^additionalCCEmail(\d+)/);

      if (matches === null) {
        continue;
      }

      additionalCCEmailList[Number(matches[1])] = quoteActionParameters[key];
    }

    const props = Object.assign(
      {
        // set default customerEmail value as "N/A" it will be overwritten by action props if available
        customerEmail: na,
      },
      quoteActionParameters
    );

    // filter method removes empty array elements
    props.additionalCCEmailList = additionalCCEmailList.filter(value => value).join(', ') || na;
    props.includeQuickQuote = intl.formatMessage({ id: props.includeQuickQuote === 'true' ? 'msg_yes' : 'msg_no' });

    return TemplateStringReplace(templateString, props);
  },
  TOGGLE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_sales_ops_toggle_history' });
    let props = {};

    for (let key in quoteActionParameters) {
      if (!quoteActionParameters.hasOwnProperty(key)) {
        continue;
      }

      props.toggleType = intl.formatMessage({ id: SALES_OPS_TOGGLES[key] });
      props.toggleAction = intl.formatMessage({ id: quoteActionParameters[key] === 'true' ? 'msg_yes' : 'msg_no' });
    }

    return TemplateStringReplace(templateString, props);
  },
  QUOTE_EXPIRATION_DATE_OVERRIDE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_expiration_date_override_history' });
    const date = quoteActionParameters.expirationDateOverrideAfter;

    const props = {
      dateAction:
        date === 'null'
          ? intl.formatMessage({ id: 'msg_removed' })
          : intl.formatMessage({ id: 'msg_changed_to' }) + ' ' + date,
    };

    return TemplateStringReplace(templateString, props);
  },
  FINANCING_OPTION_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_financing_options_changed_history' });
    const { financingOptionAfter } = quoteActionParameters;

    const props = {
      financingOptionAfter:
        financingOptionAfter === FINANCING_OPTIONS.ALL_INCLUSIVE_STAR2STAR
          ? intl.formatMessage({ id: 'msg_all_inclusive' })
          : intl.formatMessage({ id: 'msg_rental_great_america' }),
    };

    return TemplateStringReplace(templateString, props);
  },
  RENTAL_AGREEMENT_ON_FILE_OVERRIDE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_rental_agreement_on_file_override_history' });
    const overrideState = quoteActionParameters.greatAmericaRentalAgreementOnFileOverrideAfter;

    const props = {
      onFileAction:
        overrideState === 'null'
          ? intl.formatMessage({ id: 'msg_removed' })
          : intl.formatMessage({ id: 'msg_changed_to' }) +
            ' ' +
            intl.formatMessage({ id: overrideState === 'true' ? 'msg_yes' : 'msg_no' }),
    };

    return TemplateStringReplace(templateString, props);
  },
  CREDIT_DOCUMENTS_ON_FILE_OVERRIDE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_credit_document_on_file_override_history' });
    const overrideState = quoteActionParameters.creditDocumentsOnFileOverrideAfter;

    const props = {
      onFileAction:
        overrideState === 'null'
          ? intl.formatMessage({ id: 'msg_removed' })
          : intl.formatMessage({ id: 'msg_changed_to' }) +
            ' ' +
            intl.formatMessage({ id: overrideState === 'true' ? 'msg_yes' : 'msg_no' }),
    };

    return TemplateStringReplace(templateString, props);
  },
  EXISTING_DAAS_CUSTOMER_OVERRIDE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_daas_override_history' });
    const overrideState = quoteActionParameters.existingDaaSCustomerOverrideAfter;

    const props = {
      overrideState:
        overrideState === 'null'
          ? intl.formatMessage({ id: 'msg_removed' })
          : intl.formatMessage({ id: 'msg_changed_to' }) +
            ' ' +
            intl.formatMessage({ id: overrideState === 'true' ? 'msg_yes' : 'msg_no' }),
    };

    return TemplateStringReplace(templateString, props);
  },
  QUOTE_DAAS_WORKSPACE_TYPE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_quote_daas_workspace_type_changed' });
    const workspaceType = quoteActionParameters.quoteDaaSWorkspaceTypeAfter;

    const props = {
      workspaceType: intl.formatMessage({ id: WORKSPACE_TYPES_QUOTE_HISTORY[workspaceType] }),
    };

    return TemplateStringReplace(templateString, props);
  },
  CUSTOMER_DAAS_WORKSPACE_TYPE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_customer_daas_workspace_type_changed' });
    const workspaceType = quoteActionParameters.customerDaaSWorkspaceTypeAfter;

    const props = {
      workspaceType: intl.formatMessage({ id: WORKSPACE_TYPES_QUOTE_HISTORY[workspaceType] }),
    };

    return TemplateStringReplace(templateString, props);
  },
  QUOTE_DAAS_SCHEDULE_TYPE_OVERRIDE_SAVED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_quote_daas_schedule_type_changed' });
    const scheduleType = quoteActionParameters.quoteDaaSScheduleTypeAfter;

    const props = {
      scheduleType: intl.formatMessage({ id: SCHEDULE_TYPES_QUOTE_HISTORY[scheduleType] }),
    };

    return TemplateStringReplace(templateString, props);
  },
  LOCATION_DAAS_SCHEDULE_TYPE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_location_daas_schedule_type_changed' });
    const scheduleType = quoteActionParameters.locationDaaSScheduleTypeAfter;

    const props = {
      scheduleType: intl.formatMessage({ id: SCHEDULE_TYPES_QUOTE_HISTORY[scheduleType] }),
    };

    return TemplateStringReplace(templateString, props);
  },
  SWITCHVOX_MAINT_TYPE_OVERRIDE_CHANGED: (data, intl) => {
    const { switchvoxOnPremMaintTypeAfter } = data.quoteActionParameters;

    // Removed
    if (switchvoxOnPremMaintTypeAfter === 'null') {
      return intl.formatMessage({ id: 'msg_quote_history_action_switchvox_maint_type_override_changed_removed' });
    }
    // Updated
    else {
      const templateString = intl.formatMessage({
        id: 'msg_quote_history_action_switchvox_maint_type_override_changed',
      });
      return TemplateStringReplace(templateString, { switchvoxOnPremMaintTypeAfter });
    }
  },
  LOCATION_SWITCHVOX_MAINT_TYPE_CHANGED: (data, intl) => {
    const { switchvoxOnPremMaintTypeAfter } = data.quoteActionParameters;

    // Removed
    if (switchvoxOnPremMaintTypeAfter === 'null') {
      return intl.formatMessage({ id: 'msg_quote_history_action_location_switchvox_maint_type_changed_removed' });
    }
    // Updated
    else {
      const templateString = intl.formatMessage({
        id: 'msg_quote_history_action_location_switchvox_maint_type_changed',
      });
      return TemplateStringReplace(templateString, { switchvoxOnPremMaintTypeAfter });
    }
  },
  SWITCHVOX_MAINT_TERM_OVERRIDE_CHANGED: (data, intl) => {
    const { switchvoxOnPremMaintTermYearsAfter } = data.quoteActionParameters;

    // Removed
    if (switchvoxOnPremMaintTermYearsAfter === 'null') {
      return intl.formatMessage({ id: 'msg_quote_history_action_switchvox_maint_term_override_changed_removed' });
    }
    // Updated
    else {
      const templateString = intl.formatMessage({
        id: 'msg_quote_history_action_switchvox_maint_term_override_changed',
      });
      return TemplateStringReplace(templateString, { switchvoxOnPremMaintTermYearsAfter });
    }
  },
  LOCATION_SWITCHVOX_MAINT_TERM_CHANGED: (data, intl) => {
    const { switchvoxOnPremMaintTermYearsAfter } = data.quoteActionParameters;

    // Removed
    if (switchvoxOnPremMaintTermYearsAfter === 'null') {
      return intl.formatMessage({ id: 'msg_quote_history_action_location_switchvox_maint_term_changed_removed' });
    }
    // Updated
    else {
      const templateString = intl.formatMessage({
        id: 'msg_quote_history_action_location_switchvox_maint_term_changed',
      });
      return TemplateStringReplace(templateString, { switchvoxOnPremMaintTermYearsAfter });
    }
  },
  CUSTOMER_CONTACT_CENTER_CONCURRENCY_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_customer_level_contact_center_concurrency_changed_to' });
    const toggleState = quoteActionParameters.customerContactCenterConcurrencyAfter;

    const props = {
      toggleState: intl.formatMessage({ id: toggleState === 'true' ? 'msg_yes' : 'msg_no' }),
    };

    return TemplateStringReplace(templateString, props);
  },
  CUSTOMER_NAME_UPDATED: (data, intl) => {
    const quoteActionParameters = data?.quoteActionParameters || {};
    const { customerNameBefore, customerNameAfter } = quoteActionParameters;

    if (!customerNameBefore || !customerNameAfter) {
      return intl.formatMessage({ id: 'msg_quote_history_action_customer_name_updated' });
    }

    const templateString = intl.formatMessage({
      id: 'msg_quote_history_action_customer_name_updated_with_names',
    });

    return TemplateStringReplace(templateString, { customerNameBefore, customerNameAfter });
  },
  RVP_NRC_DISCOUNT_CHANGED: (data, intl) => {
    const props = {
      currentDiscount: data.quoteActionParameters.currentDiscount,
      newDiscount: data.quoteActionParameters.newDiscount,
    };
    const templateString = intl.formatMessage({
      id: 'msg_rvp_nrc_discount_changed',
    });

    return TemplateStringReplace(templateString, props, '#');
  },
  RVP_RC_DISCOUNT_CHANGED: (data, intl) => {
    const props = {
      currentDiscount: data.quoteActionParameters.currentDiscount,
      newDiscount: data.quoteActionParameters.newDiscount,
    };
    const templateString = intl.formatMessage({
      id: 'msg_rvp_rc_discount_changed',
    });

    return TemplateStringReplace(templateString, props, '#');
  },
  QUOTE_SANGOMA_CX_USER_TYPE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_quote_sangoma_cx_user_type_after' });
    const sangomaCXUser = quoteActionParameters.quoteSangomaCXUserTypeAfter;

    const props = {
      sangomaCXUser: intl.formatMessage({ id: SANGOMA_CX_USER_TYPES_QUOTE_HISTORY[sangomaCXUser] }),
    };

    return TemplateStringReplace(templateString, props);
  },
  CUSTOMER_SANGOMA_CX_USER_TYPE_CHANGED: (data, intl) => {
    const { quoteActionParameters } = data;
    const templateString = intl.formatMessage({ id: 'msg_customer_sangoma_cx_user_type_after' });
    const sangomaCXUser = quoteActionParameters.customerSangomaCXUserTypeAfter;

    const props = {
      sangomaCXUser: intl.formatMessage({ id: SANGOMA_CX_USER_TYPES_QUOTE_HISTORY[sangomaCXUser] }),
    };

    return TemplateStringReplace(templateString, props);
  },
  CUSTOMER_SANGOMA_CX_CONCURRENCY_CHANGED: (data, intl) => {
    const props = {
      toggleState: intl.formatMessage({
        id: data.quoteActionParameters.sangomaCXConcurrencyAfter === 'true' ? 'msg_yes' : 'msg_no',
      }),
    };
    const templateString = intl.formatMessage({ id: 'msg_customer_sangoma_cx_concurrency_changed' });

    return TemplateStringReplace(templateString, props);
  },
  CONTRACT_TERM_LENGTH_CHANGED: (data, intl) => {
    const { contractTermLengthBefore, contractTermLengthAfter } = data.quoteActionParameters;

    const templateString = intl.formatMessage({
      id: 'msg_contract_term_length_changed',
    });

    return TemplateStringReplace(templateString, { contractTermLengthBefore, contractTermLengthAfter });
  },
  ADD_ON_FINANCE_TERM_LENGTH_CHANGED: (data, intl) => {
    const { addOnFinanceTermLengthBefore, addOnFinanceTermLengthAfter } = data.quoteActionParameters;

    const templateString = intl.formatMessage({
      id: 'msg_finance_term_length_changed',
    });

    return TemplateStringReplace(templateString, { addOnFinanceTermLengthBefore, addOnFinanceTermLengthAfter });
  },
  EXISTING_SPEECH_ANALYTICS_CUSTOMER_OVERRIDE_CHANGED: (data, intl) => {
    const { existingSpeechAnalyticsCustomerOverrideAfter } = data;

    return intl.formatMessage({
      id:
        existingSpeechAnalyticsCustomerOverrideAfter === null
          ? 'msg_quote_history_speech_analytics_override_removed'
          : 'msg_quote_history_speech_analytics_override_set_to_no',
    });
  },
};

class QuoteHistoryComponent extends Component {
  static propTypes = {
    open: PropTypes.bool,
    quoteId: PropTypes.number,
    onClose: PropTypes.func,
    quoteHistory: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    open: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      // 1 for asc, -1 for desc
      sortDirection: 1,
    };

    this.handleClose = this.handleClose.bind(this);
    this.getTableRow = this.getTableRow.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
  }

  getActionNote(data) {
    const { quoteAction, quoteActionParameters } = data;

    if (typeof QUOTE_HISTORY_ACTIONS_MAP[quoteAction] === 'function') {
      return QUOTE_HISTORY_ACTIONS_MAP[quoteAction].call(null, data, this.props.intl);
    }

    // Return quoteAction enum if no mapping found or it is not a string
    if (!QUOTE_HISTORY_ACTIONS_MAP[quoteAction] || typeof QUOTE_HISTORY_ACTIONS_MAP[quoteAction] !== 'string') {
      return quoteAction;
    }

    let templateString = QUOTE_HISTORY_ACTIONS_MAP[quoteAction];

    if (templateString.match(/^msg_/)) {
      templateString = this.props.intl.formatMessage({ id: templateString });
    }

    if (!quoteActionParameters) {
      return templateString;
    }

    return TemplateStringReplace(templateString, quoteActionParameters);
  }

  getTableRow(rowData) {
    const key = `quote-history-item-${rowData.id}`;
    const userName = `${rowData.userFirstName ? rowData.userFirstName : ''} ${
      rowData.userLastName ? rowData.userLastName : ''
    }`;
    const date = dayjs(rowData.created).format('MM-DD-YYYY hh:mm:ss A');
    const href = `${window.location.origin}/quote/quotes/${rowData.quoteRevisionId}`;
    const rowClassName = classNames({
      strong: rowData.quoteRevisionId === this.props.quoteId,
    });

    return (
      <tr className={rowClassName} key={key}>
        <td className="id align-right auto-width-nowrap">
          <ExternalLink path={href} title={rowData.quoteRevisionId} />
        </td>
        <td className="username auto-width-nowrap">
          <ExternalLink path={href} title={userName} />
        </td>
        <td className="action">
          <ExternalLink path={href} title={this.getActionNote(rowData)} />
        </td>
        <td className="date auto-width-nowrap">
          <ExternalLink path={href} title={date} />
        </td>
      </tr>
    );
  }

  handleSortChange() {
    let { sortDirection } = this.state;

    sortDirection *= -1;

    this.setState({
      sortDirection,
    });
  }

  render() {
    const { sortDirection } = this.state;

    let dialogProps = {
      bodyClassName: 'quote-history-dialog',
      contentClassName: 'quote-history-dialog-content',
      actionsContainerClassName: 'quote-history-dialog-actions',
    };

    dialogProps.title = (
      <div>
        <header className="quote-history-title">
          <span className="quote-history-title-text">{translator.getMessage('msg_history')}</span>
        </header>
      </div>
    );

    dialogProps.actions = [
      <button
        id="quote-history-cancel-button"
        key="quote-history-close-button"
        className="quote-history-dialog-btn-cancel"
        onClick={this.handleClose}
      >
        {translator.getMessage('msg_close')}
      </button>,
    ];

    const sortableClassName = classNames({
      sortable: true,
      asc: sortDirection === 1,
      desc: sortDirection === -1,
    });
    const sortedTableRows = this.props.quoteHistory.sort((a, b) => sortDirection * (a.created - b.created));

    return (
      <Dialog {...dialogProps} onRequestClose={this.handleClose} isOpen={this.props.open}>
        <table className="rq-table striped sticky hover quote-history-table" key="quote-history-information">
          <thead>
            <tr>
              <th className="align-right">{translator.getMessage('msg_quote_id')}</th>
              <th>{translator.getMessage('msg_user')}</th>
              <th>{translator.getMessage('msg_action')}</th>
              <th className={sortableClassName} onClick={this.handleSortChange}>
                {translator.getMessage('msg_timestamp')}
              </th>
            </tr>
          </thead>
          <tbody>{sortedTableRows.map(item => this.getTableRow(item))}</tbody>
        </table>
      </Dialog>
    );
  }

  handleClose() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }
}

export default injectIntl(QuoteHistoryComponent);
